<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            codeGroupCd="CHECK_UP_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="checkupTypeCd"
            label="건강검진 종류"
            v-model="searchParam.checkupTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진기간"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="건강검진 목록"
      tableId="table"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'check-up-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'checkupTypeName',
            field: 'checkupTypeName',
            label: '건강검진 종류',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupName',
            field: 'checkupName',
            label: '제목',
            style: 'width:400px',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'hospitalName',
            field: 'hospitalName',
            label: '병원',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진기간',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        checkupTypeCd: null,
        startYmd: '',
        endYmd: '',
        plantCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      period: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.plan.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(result) {
      this.popupOptions.target = () => import(`${"./checkUpDetail.vue"}`);
      this.popupOptions.title = '건강검진 계획/결과 상세';
      this.popupOptions.param = {
        heaCheckupPlanId: result ? result.heaCheckupPlanId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
